import React from "react";
import {APIProvider, Map, Marker} from '@vis.gl/react-google-maps';

const EmbeddedMap = () => {
    const locations = [
        {
            lat: -19.919482913178108,
            lng: -43.94104964358998,
        },
        {
            lat: -21.128965039718754,
            lng: -42.36823280882723,
        },
        {
            lat: -20.410140513975012,
            lng: -42.89646560088274,
        },
        {
            lat: -20.75615766804737,
            lng: -42.87934574287874,
        },
    ];

    const center = {
        lat: (-19.919482913178108 -21.128965039718754-20.410140513975012-20.75615766804737)/4,
        lng: (-43.94104964358998-42.36823280882723-42.89646560088274-42.87934574287874)/4
    }

    return (
        <div className="mx-auto w-11/12 md:w-4/5 xl:w-2/3 2xl:w-1/2 aspect-video overflow-hidden rounded-small drop-shadow-2xl my-10">
            <APIProvider apiKey={'AIzaSyBSjLf2laWHdAAlj20u5MLk5zELdD9v934'}>
                <Map
                    defaultCenter={center}
                    defaultZoom={9}
                    gestureHandling={'greedy'}
                    disableDefaultUI={true}
                >
                    {locations.map((location) => <Marker
                        position={location}
                    />)
                    }
                </Map>
            </APIProvider>
        </div>

    );

};

export default EmbeddedMap;