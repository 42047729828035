import React, {useState} from "react";
import './App.css';
import Navbar from "./components/Navbar";

import depoimentos from './depoimentos.json';
import dadosProjetos from './dadosProjetos.json';
import ScrollToTop from "./utils/Navigation/ScrollToTop";
import Router from "./utils/Router";
import SetColorScheme from "./utils/Navigation/SetColorScheme";

function App() {
  const [page, setPage] = useState("home");

  let backgroundImg;

  console.log(depoimentos)
  console.log(dadosProjetos)

  if(page === "home" || page === "sobre"){
    backgroundImg = '/resources/imgs/background/Background_Purple.svg';
  }
  if(page === "portfolio"){
    backgroundImg = '/resources/imgs/background/Background_Red.svg';
  }
  if(page === "servicos"){
    backgroundImg = '/resources/imgs/background/Background_Green.svg';
  }
  if(page === "contato"){
    backgroundImg = '/resources/imgs/background/Background_Orange.svg';
  }

  const colorVariants = {
    "home": {
      "text": "text-[#422B86]",
      "border": "border-[#422B86]"
    },
    "portfolio": {
      "text": "text-[#BE3455]",
      "border": "border-[#BE3455]"
    },
    "servicos": {
      "text": "text-[#88B017]",
      "border": "border-[#88B017]"
    },
    "contato": {
      "text": "text-[#E36F4A]",
      "border": "border-[#E36F4A]"
    },
    "sobre": {
      "text": "text-[#422B86]",
      "border": "border-[#422B86]"
    },
  }

  return (
      <div
          className="bg-cover bg-center bg-scroll"
          style={{ backgroundImage: `url(${backgroundImg})` }}
      >
        <div className={"fixed top-0 right-0 m-8 p-3 text-xs font-mono text-white h-6 w-6 rounded-full flex items-center justify-center bg-gray-700 sm:bg-pink-500 md:bg-orange-500 lg:bg-green-500 xl:bg-blue-500 2xl:bg-purple-500 z-50"}>
          <div className={"block sm:hidden md:hidden lg:hidden xl:hidden"}>al</div>
          <div className={"hidden sm:block md:hidden lg:hidden xl:hidden"}>sm</div>
          <div className={"hidden sm:hidden md:block lg:hidden xl:hidden"}>md</div>
          <div className={"hidden sm:hidden md:hidden lg:block xl:hidden"}>lg</div>
          <div className={"hidden sm:hidden md:hidden lg:hidden xl:block 2xl:hidden"}>xl</div>
          <div className={"hidden sm:hidden md:hidden lg:hidden xl:hidden 2xl:block"}>2xl</div>
        </div>
        <Navbar page={page} click={setPage} colors={colorVariants[page]}/>
        <ScrollToTop/>
        <SetColorScheme setPage={setPage}/>
        <Router setPage={setPage} page={page} colorVariants={colorVariants}/>
      </div>
  );
}

export default App;
