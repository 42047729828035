import React from "react";
import FormfacadeEmbed from "@formfacade/embed-react";

const Forms = () => {
    return (
        <div className="w-11/12">
            <FormfacadeEmbed
            formFacadeURL="https://formfacade.com/include/107636786550911400781/form/1FAIpQLSdxIvd0Wxc9YATLPgomc1z5SGcUsZRx6Uk1dksYMfYVmY-bFg/classic.js/?div=ff-compose"
            onSubmitForm={() => console.log('Form submitted')}
            />
        </div>
    )
};

export default Forms;