import React from 'react';
import Footer from "../components/Footer";
import {Link} from "react-router-dom";

const Home = ({ click, colors }) => {
    return (
        <div>
            <div className="flex flex-col min-h-screen w-screen">
                <div id='flex-container' className={`items-center flex flex-row flex-wrap justify-center pt-32 sm:pt-40 lg:pt-48 xl:pt-52 w-screen`}>
                    <img src="/resources/imgs/home/header.png" className={"w-11/12 2xl:w-4/5"} alt="header"/>
                </div>
                <div className="flex flex-row flex-grow justify-center pt-10 pb-10 lg:pb-28 w-screen">
                    <img src="/resources/imgs/home/capa.png" className={"w-11/12 2xl:w-4/5"} alt="capa"/>
                </div>
                <div className={"flex flex-row flex-grow justify-center w-screen pt-5 sm:pt-20"}>
                    <div className="flex flex-col sm:w-11/12 2xl:w-4/5">
                        <div className={`flex flex-row flex-wrap pb-10 mx-auto md:mx-0 text-4xl sm:text-6xl lg:text-7xl xl:text-8xl`}>
                            <div className={`font-sans ${colors["text"]} px-1 sm:px-2`}>
                                SEJA <span className='font-bold'>BEM-VINDO!</span>
                            </div>
                        </div>
                        <div className="flex flex-col justify-center md:flex-row">
                            <div className="flex px-10 xl:px-20 2xl:px-24 py-2 md:py-10 lg:py-0 items-center w-7/12 sm:w-2/5 mx-auto">
                                <img src="/resources/imgs/home/cachorro.png" alt="Cachorro" className={"mx-auto 2xl:max-h-[550px]"}/>
                            </div>
                            <div className="flex flex-col mx-auto w-11/12 sm:w-4/5 md:w-3/5 h-full justify-between">
                                <div className={`border ${colors["border"]} border-2 rounded-full p-2 my-5 lg:p-4 lg:my-10`}>
                                    <p className={`font-sans ${colors["text"]} italic text-lg sm:text-2xl lg:text-3xl xl:text-4xl text-center leading-none sm:leading-7 p-1 lg:p-2`}>Nós somos um escritório de arquitetura para pessoas cansadas de projetos chatos.</p>
                                </div>
                                <img src="/resources/imgs/home/barra.png" className={"md:my-4"} alt="Barra"/>
                                <hr className={`border-1 my-4 ${colors["border"]}`}/>
                                <img src="/resources/imgs/home/apresentacao_servicos.png" alt="servicos" className={"hidden mt-2 sm:mt-4 lg:block"}/>
                                <img src="/resources/imgs/home/apresentacao_servicos_small.png" alt="servicos" className={"block mt-2 sm:mt-4 lg:hidden"}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center w-11/12 xl:w-4/5 mx-auto">
                    <img src="/resources/imgs/home/portfolio_header.png" className={"pb-5 md:pb-10 lg:pb-20 pt-16 sm:pt-24 w-2/3 md:w-1/2"} alt="HEADER"/>
                    <div className={"pb-5 lg:pb-10"}>
                        <img src="/resources/imgs/home/portfolio_images.png" alt="IMAGES"/>
                    </div>
                    <Link to="/portfolio" onClick={() => click("portfolio")} className={`border ${colors["border"]} border-2 rounded-full m-2 md:px-3`}>
                        <div className={`font-sans text-md md:text-xl lg:text-2xl ${colors["text"]} my-1 mx-2`}>Ver mais</div>
                    </Link>
                </div>
                <div className="flex flex-col justify-center items-center py-10 sm:py-20 lg:py-32 w-11/12 xl:w-4/5 mx-auto text-xl sm:text-2xl md:text-4xl">
                    <div className={`flex pb-5 flex-row`}>
                        <div className={`font-sans ${colors["text"]} px-1 mx-auto xl:mx-0`}>
                            SUA CASA TEM DE <span className='font-bold'>TER A SUA CARA</span>
                        </div>
                    </div>
                    <img src="/resources/imgs/home/chamada.png" alt="CHAMADA"/>
                </div>
            </div>
            <div className="flex flex-col w-full pb-10 md:flex-row content-end">
                <div className="flex flex-col flex-grow items-center justify-center px-auto lg:px-10 pb-20 xl:pb-0 w-11/12 sm:w-3/5 mx-auto">
                    <div className={`font-sans underline text-4xl sm:text-5xl lg:text-6xl ${colors["text"]} my-8 mx-auto`}>Não se acanhe!</div>
                    <Link to="/contato" onClick={() => click("contato")} className={`border ${colors["border"]} border-2 rounded-full mx-auto`}>
                        <p className={`font-sans ${colors["text"]} text-md sm:text-xl lg:text-2xl text-center px-5 py-2`}> Preencha nosso formulário de orçamento</p>
                    </Link>
                </div>
                <div className={"ml-auto w-3/5"}>
                    <img className={"ml-auto"} src="/resources/imgs/home/banner.png" alt="BANNER"/>
                </div>
            </div>
            <Footer colors={colors}/>
        </div>
    );
};

export default Home;