import React from 'react';
import Footer from "../components/Footer";
import {Link} from "react-router-dom";

const Portfolio = ( { click, colors } ) => {

    const projetos = [
        "aptoGabiJoao",
        "aptoDaiane",
        "aptoVictoria",
        "aptoCynthia",
        "cozinhaSA",
        "aptoLilianLeandro",
        "aptoNathalia",
        "quartoLiz",
        "quartoGiuliana",
        "salaLuisa",
        "escritorioMemories",
    ]

    return (
        <div>
            <div className="flex flex-col min-h-screen w-11/12 lg:w-4/5 mx-auto">
                <div id='flex-container' className={`items-center flex flex-row flex-wrap justify-center pt-32 sm:pt-40 lg:pt-48 xl:pt-52 text-5xl sm:text-6xl md:text-7xl xl:text-8xl w-full`}>
                    <div className={`font-sans ${colors["text"]} px-1`}>
                        NOSSOS <span className='font-bold'>PROJETOS</span>
                    </div>
                </div>
                <div className="container mx-auto py-10 w-full">
                    <div className="grid grid-cols-3 gap-4 justify-items-center">
                        {projetos.map((name) =>
                            <Link to={"/portfolio/"+name}>
                                <img src={"/resources/imgs/portfolio/projetos/"+name+"/card.png"} alt=""/>
                            </Link>
                        )}
                    </div>
                </div>
                <div className="flex flex-col mx-auto pb-10 justify-center pt-8 md:pt-32 md:flex-row lg:flex-row-reverse">
                    <div className="flex flex-col flex-grow items-center justify-center px-2 lg:px-10 pb-20 xl:pb-0 w-3/5 mx-auto my-auto">
                        <div className={`font-sans underline text-4xl sm:text-5xl xl:text-6xl ${colors["text"]} my-8 mx-auto`}>Quer saber mais?</div>
                        <Link to="/contato" onClick={() => click("contato")} className={`border ${colors["border"]} border-2 rounded-full mx-auto`}>
                            <p className={`font-sans ${colors["text"]} text-md sm:text-lg lg:text-xl text-center p-1.5`}> Preencha nosso formulário de orçamento</p>
                        </Link>
                    </div>
                    <div className={"mx-auto md:ml-auto w-3/5"}>
                        <img src="/resources/imgs/portfolio/banner.png" alt="BANNER"/>
                    </div>/
                </div>
            </div>
            <Footer colors={colors}/>
        </div>
    );
};

export default Portfolio;