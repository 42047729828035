import React from 'react';
import Footer from "../components/Footer";
import Faq from "../components/FAQ";
import {Link} from "react-router-dom";

const Servicos = ({ click, colors }) => {
    return (
        <div>
            <div className="flex flex-col min-h-screen w-screen">
                <div id='flex-container' className={`items-center flex flex-row flex-wrap justify-center pt-32 sm:pt-40 lg:pt-48 xl:pt-52 w-full mx-auto text-5xl sm:text-6xl md:text-7xl lg:text-8xl xl:text-9xl`}>
                    <div className={`font-sans ${colors["text"]}`}>
                        NOSSOS <span className={"font-bold"}>SERVIÇOS</span>
                    </div>
                </div>
                <div className="flex flex-row justify-center py-10 sm:py-20 w-full xl:w-4/5 mx-auto gap-2">
                    <a href='#projeto'>
                        <img src="/resources/imgs/servicos/projeto_arquitetonico.png" className="flex-1 px-10 hidden md:block" alt="PROJETO"/>
                        <img src="/resources/imgs/servicos/projeto_arquitetonico_slim.png" className="flex-1 pl-2 md:hidden" alt="PROJETO"/>
                    </a>
                    <a href='#consultoria'>
                        <img src="/resources/imgs/servicos/consultoria.png" className="flex-1 px-10 hidden md:block" alt="PROJETO"/>
                        <img src="/resources/imgs/servicos/consultoria_slim.png" className="flex-1 pr-2 md:hidden" alt="PROJETO"/>
                    </a>
                </div>
                <hr className={`border-t-2 ${colors["border"]} w-full`} />
                <div id='projeto' className="flex flex-col w-full items-center">
                    <div className="flex flex-col justify-center py-10 sm:py-20 w-11/12 xl:w-4/5 items-center">
                        <img src="/resources/imgs/servicos/projeto_banner_large.png" alt="PROJETO BANNER" className={"hidden lg:block"}/>
                        <img src="/resources/imgs/servicos/projeto_banner_medium.png" alt="PROJETO BANNER" className={"hidden md:block lg:hidden"}/>
                        <img src="/resources/imgs/servicos/projeto_banner_small.png" alt="PROJETO BANNER" className={"block md:hidden"}/>
                        <p className={`font-sans ${colors["text"]} italic w-4/5 text-2xl xl:text-3xl 2xl:text-4xl py-10 xl:py-16 text-center`}>Precisa quebrar paredes, integrar ambientes, repensar a parte elétrica ou refazer a sua cozinha?</p>
                        <p className={`font-sans ${colors["text"]}  text-2xl xl:text-3xl 2xl:text-4xl text-center pt-5`}>NESSA MODALIDADE DE PROJETO</p>
                        <p className={`font-sans ${colors["text"]} font-bold text-4xl lg:text-5xl text-center`}>TRANSFORMAMOS COMPLETAMENTE O SEU ESPAÇO</p>
                        <Link to="/contato" onClick={() => click("contato")} className={`border ${colors["border"]} border-2 rounded-full m-10`}>
                            <p className={`hidden md:block font-sans ${colors["text"]} text-xl lg:text-2xl text-center p-2`}> Essa é a modalidade de projeto para você, faça um orçamento!</p>
                            <p className={`md:hidden font-sans ${colors["text"]} text-xl lg:text-2xl text-center py-2 px-7`}> Faça um orçamento!</p>
                        </Link>
                        <div className="flex flex-row pt-10 mx-auto justify-center">
                            <img src="/resources/imgs/servicos/arquitetura_boxes_large.png" alt="servicos" className={"hidden lg:block"}/>
                            <img src="/resources/imgs/servicos/arquitetura_boxes_medium.png" alt="servicos" className={"hidden md:block lg:hidden"}/>
                            <img src="/resources/imgs/servicos/arquitetura_boxes_small.png" alt="servicos" className={"block md:hidden"}/>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center py-16 w-11/12 xl:w-4/5 lg:justify-left">
                        <div className={`font-sans text-4xl sm:text-5xl xl:text-6xl ${colors["text"]} mx-auto lg:mx-0`}> MAS COMO </div>
                        <div className={`font-sans font-bold text-4xl sm:text-5xl xl:text-6xl ${colors["text"]} mx-auto lg:mx-0 pb-5 sm:pb-10 text-center lg:text-left`}> FUNCIONA O PROJETO? </div>
                        <img src="/resources/imgs/servicos/projeto_cards_large.png" className={"py-5 hidden lg:block"} alt="PROJETO"/>
                        <img src="/resources/imgs/servicos/projeto_cards_medium.png" className={"py-5 hidden md:block lg:hidden"} alt="PROJETO"/>
                        <img src="/resources/imgs/servicos/projeto_cards_small.png" className={"py-5 block md:hidden"} alt="PROJETO"/>
                    </div>
                    <div id='consultoria' className="flex flex-col justify-center py-20 w-11/12 xl:w-4/5 items-center">
                        <img src="/resources/imgs/servicos/consultoria_banner.png" className="py-10" alt="PROJETO BANNER"/>
                        <div className="flex flex-row pt-10 mx-auto justify-center">
                            <img src="/resources/imgs/servicos/consultoria_boxes_large.png" alt="servicos" className={"hidden lg:block"}/>
                            <img src="/resources/imgs/servicos/consultoria_boxes_medium.png" alt="servicos" className={"hidden md:block lg:hidden"}/>
                            <img src="/resources/imgs/servicos/consultoria_boxes_small.png" alt="servicos" className={"block md:hidden"}/>
                        </div>
                    </div>
                    <div className="flex flex-col w-full bg-[#88B017] bg-opacity-15 items-center">
                        <div className={"p-10"}>
                            <div className={`font-sans text-2xl md:text-3xl ${colors["text"]}`}>APENAS</div>
                            <div className={`font-sans font-bold text-7xl md:text-8xl ${colors["text"]}`}>R$850,00</div>
                            <div className={`font-sans text-2xl md:text-3xl ${colors["text"]}`}>POR AMBIENTE TRANSFORMADO</div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center pb-10 w-11/12 xl:w-4/5 items-center">
                        <div className="flex flex-col py-10 sm:py-32">
                            <div className={`font-sans text-3xl sm:text-5xl xl:text-6xl ${colors["text"]} mx-auto lg:mx-0`}> MAS COMO </div>
                            <div className={`font-sans font-bold text-3xl sm:text-5xl xl:text-6xl ${colors["text"]} mx-auto lg:mx-0 pb-5 sm:pb-10 text-center lg:text-left`}> FUNCIONA A CONSULTORIA? </div>
                            <img src="/resources/imgs/servicos/consultoria_cards_large.png" className={"py-5 hidden lg:block"} alt="PROJETO"/>
                            <img src="/resources/imgs/servicos/consultoria_cards_medium.png" className={"py-5 hidden md:block lg:hidden"} alt="PROJETO"/>
                            <img src="/resources/imgs/servicos/consultoria_cards_small.png" className={"py-5 block md:hidden"} alt="PROJETO"/>
                        </div>
                        <div className="flex flex-col w-full">
                            <div className="flex flex-col lg:flex-row pb-10 sm:pb-20 mx-auto lg:mx-0">
                                <div className={`font-sans text-4xl xl:text-5xl ${colors["text"]} px-1`}> QUAL É O SERVIÇO </div>
                                <div className={`font-sans font-bold text-4xl xl:text-5xl ${colors["text"]} px-1`}> CERTO PARA MIM? </div>
                            </div>
                            <img src="/resources/imgs/servicos/table.png" alt="servicos" className={"block lg:hidden"}/>
                            <img src="/resources/imgs/servicos/table_large.png" alt="servicos" className={"hidden lg:block"}/>
                            <div className="flex flex-col pt-10 sm:pt-20">
                                <div className="flex flex-row mx-auto">
                                    <img src="/resources/imgs/servicos/keyhole.svg" alt="KEYHOLE" className={"w-1/3 xl:1/5 mx-10 self-start hidden lg:block"}/>
                                    <div className="flex flex-col justify-center">
                                        <div className={`font-sans text-6xl text-[#6E852D] text-center w-full p-5 sm:p-10`}>F.A.Q.</div>
                                        <div className={`font-sans text-4xl text-[#6E852D] text-center w-full`}>Perguntas Frequentes</div>
                                    </div>
                                </div>
                                <Faq color={colors}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mx-auto pb-10 w-11/12 lg:w-4/5 justify-center pt-8 md:pt-32 md:flex-row lg:flex-row-reverse">
                    <div className="flex flex-col flex-grow items-center justify-center px-2 lg:px-5 pb-20 xl:pb-0 w-4/5 md:w-3/5 mx-auto my-auto lg:ml-auto">
                        <div className={`font-sans underline text-4xl sm:text-5xl xl:text-6xl ${colors["text"]} my-8 mx-auto`}>Quer saber mais?</div>
                        <Link to="/contato" onClick={() => click("contato")} className={`border ${colors["border"]} border-2 rounded-full mx-auto`}>
                            <p className={`font-sans ${colors["text"]} text-xl xl:text-3xl text-center px-10 py-3`}> Preencha nosso formulário de orçamento</p>
                        </Link>
                    </div>
                    <div className={"mx-auto md:ml-auto w-3/5 md:w-2/5 lg:mr-auto"}>
                        <img src="/resources/imgs/servicos/banner.png" alt="BANNER"/>
                    </div>/
                </div>
                <Footer colors={colors}/>
            </div>
        </div>
    );
};

export default Servicos;