import React, { useState } from 'react';

const AccordionItem = ({ title, content, color }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div onClick={toggleAccordion} className={`border-t-2 ${color["border"]} w-full cursor-pointer`}>
            <div className="flex flex-row flex-grow  w-full">
                <div className={`font-sans text-2xl lg:text-3xl ${color["text"]} py-8 flex-grow`}>{title}</div>
                <img src="/resources/imgs/servicos/toggle-icon.svg" alt="" className={`transform transition-transform duration-500 ${isOpen ? 'rotate-0' : 'rotate-180'} justify-end px-5`}/>
            </div>
            <div className={`transition-max-height duration-500 ease-in-out overflow-hidden font-sans text-xl ${color["text"]} text-justify  ${isOpen ? 'max-h-screen pb-8' : 'max-h-0'}`}>
                {isOpen ? content : ""}
            </div>
        </div>
    );
};

export default AccordionItem;